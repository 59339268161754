.App {
  margin: 15px;
  text-align: left;
  font-size: 28px;
}

.price {
  font-family: monospace;
  font-size: 28px;
}

.currency-box {
  margin: 30px 10px;
}

.details {
  display: flex;
  margin: 4px;
  max-width: 400px;
}

.bid, .ask {
  display: block;
  font-size: 16px;
  flex: 1;
}
.bid .price {
  font-family: monospace;
  font-size: 18px;
}

.ask .price {
  font-family: monospace;
  font-size: 18px;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: lightgray;
  }
}

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";